<template>
  <div
    style="
      padding: 16px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
    "
  >
    <div class="scroll">
      <div>
        <div class="title">蒸汽钱包相关</div>
        <div class="desc">
          蒸汽钱包内的金额为蒸汽币，目前仅为挂售用户收益的钱包。
        </div>
        <div class="desc">
          1. 蒸汽币可用于超级蒸汽商城内消费，不可提现，不可转让给他人。
        </div>
        <div class="desc">
          2. 瓶盖是玩家通过日常签到、完成任务所获得的奖励为瓶盖。
        </div>
        <div class="desc">
          3.
          玩家在求购时所支付的金额为锁定金额。若有多笔求购订单时，锁定金额会展示将所有订单的支付金额综合，便于用户查看管理。
        </div>
        <div class="desc">4.当求购成功时，求购金会转化为卖家收益。</div>
        <div class="desc">
          5.当求购失败或时玩家取消求购时，相对应订单的求购金将会原路退回。
        </div>
      </div>
      <div>
        <div class="title">求购相关</div>
        <div class="desc">
          求购是在集市中购买其他玩家挂售的未拆封卡券，超级蒸汽平台担保卡券有效并提供售后。
        </div>
        <div class="desc">
          您可以直接以当前最低在售价购买，也可以设定目标价进行求购。设定求购后，若有卖家的出价符合您的要求，系统将自动为您完成交易。
        </div>
        <div class="desc">
          1.
          求购有效期为7天。在完成交易前，您可以随时取消求购，或者7天后系统将自动取消。
        </div>
        <div class="desc">
          2.
          求购取消后，您支付的金额将原路退回。若您通过蒸汽币支付，将退回到您的钱包中。
        </div>
        <div class="desc">
          3.
          交易完成后，金额将转化为蒸汽币给卖家，无法撤销。卡券将自动拆封，无法二次挂售。
        </div>
        <div class="desc">
          4. 若您的交易过程中遭遇问题，请通过「我的-联系客服」获取支持。
        </div>
      </div>

      <div>
        <div class="title">挂售相关</div>
        <div class="desc">
          挂售是将您不想要的未拆封卡券在集市中定价，售卖给与其他玩家，以获取蒸汽币。超级蒸汽平台担保卡券有效并提供售后。
        </div>
        <div class="desc">
          1.
          目前仅支持史诗及以上品质的卡券类补给进行挂售，部分特殊卡券不支持挂售。
        </div>
        <div class="desc">
          2. 挂售后，若有买家的出价符合您的要求，系统将自动为您完成交易。
        </div>
        <div class="desc">3. 在交易完成前，您可以随时取消挂售。</div>
        <div class="desc">
          4. 交易完成后，买家支付的金额将转为蒸汽币到您的钱包中，无法撤销。
        </div>
        <div class="desc">
          5.
          为了避免市场中出现不合理的定价。集市中的商品，定价上限为该商品的官方价。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "vant";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CommonProblem extends Vue {}
</script>

<style lang="scss" scoped>
.scroll {
  overflow: scroll;
  height: 100%;
}
.title {
  font-size: 16px;
  font-weight: 800;
  color: #121212;
  margin-bottom: 20px;
}
.desc {
  font-size: 14px;
  color: #121212;
  margin-bottom: 18px;
}
</style>